import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { prettifyDates, prettifyExcelDates } from '../../../../helpers/prettifiers';
import { check_for_existing_lead_field } from '../../utils';


const processCustomField = (customField, transformedItem, t) => {
    if (typeof customField === 'string') {
        transformedItem[(t("Custom Field"))] = customField;
    } else if (typeof customField === 'object' && !Array.isArray(customField)) {
        if(customField?.key==="CustomField")  transformedItem[(t("Custom Field"))] = customField["value"];
        else transformedItem[customField["key"]] = customField["value"];

    } else if (Array.isArray(customField)) {
        // Convert array of objects to a single object with merged key-value pairs
        customField.forEach(obj => {
            if (typeof obj === 'object' && obj !== null) {
                transformedItem[obj["key"]] = obj["value"];
            }
        });
    }
}


const transformDataForMatrix = (leads, t) => {
    const transformedLeads = [];
    const headers = new Set();

    // First pass: collect all unique custom field keys
    leads.forEach(lead => {
        if (typeof lead.CustomField === 'string') {
            headers.add(t("CustomField"));
        } else if (Array.isArray(lead.CustomField)) {
            lead.CustomField.forEach(field => {
                headers.add(field.key);
            });
        } else if (typeof lead.CustomField === 'object' && lead.CustomField !== null) {
            headers.add(lead.CustomField.key);
        }
    });

    // Second pass: transform each lead
    leads.forEach(lead => {
        const transformedLead = {};
        delete transformedLead.CustomField; // Remove the original CustomField
        
        headers.forEach(header => {
            if (typeof lead.CustomField === 'string') {
                if (header === t("CustomField")) {
                    transformedLead[header] = lead.CustomField;
                } else {
                    transformedLead[header] = null;
                }
            } else if (Array.isArray(lead.CustomField)) {
                const field = lead.CustomField.find(field => field.key === header);
                transformedLead[header] = field ? field.value : null;
            } else if (typeof lead.CustomField === 'object' && lead.CustomField !== null) {
                if (lead.CustomField.key === header) {
                    transformedLead[header] = lead.CustomField.value;
                } else {
                    transformedLead[header] = null;
                }
            } else {
                transformedLead[header] = null;
            }
        });

        transformedLeads.push(transformedLead);
    });

    return { headers: Array.from(headers), data: transformedLeads };
};


const makeExcelMoreUserFriendly = (t, excelData) => {

    let friendlyExcelData = JSON.parse(JSON.stringify(excelData));

    return friendlyExcelData.map(item => {

        const transformedItem = {};

        if (check_for_existing_lead_field(excelData, 'createdAt')) transformedItem[(t("Date"))] = prettifyExcelDates(item.createdAt);
        if (check_for_existing_lead_field(excelData, 'FullName')) transformedItem[(t("FullName"))] = item.FullName;
        if (check_for_existing_lead_field(excelData, 'Name')) transformedItem[(t("Name"))] = item.Name;
        if (check_for_existing_lead_field(excelData, 'Surname')) transformedItem[(t("Surname"))] = item.Surname;
        if (check_for_existing_lead_field(excelData, 'Email')) transformedItem[(t("Email"))] = item.Email;
        if (check_for_existing_lead_field(excelData, 'Tel')) transformedItem[(t("Tel"))] = item.Tel;
        if (check_for_existing_lead_field(excelData, 'City')) transformedItem[(t("City"))] = item.City;
        if (check_for_existing_lead_field(excelData, 'CustomField')) {
            processCustomField(item.CustomField, transformedItem, t);
        }
        return transformedItem;
    });
}

const exportExcel = (t, excelData, fileName) => {

    const fileType='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const ws = XLSX.utils.json_to_sheet(makeExcelMoreUserFriendly(t, excelData));
    const wb = { Sheets: {'data':ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}


export {exportExcel, transformDataForMatrix}
    